import React from "react";

import burger from "../../Assets/Burger.svg";
import {AiOutlineClose} from "react-icons/ai";

import './index.css';
import './mobile.css';

function MenuButton(props){

    const onClickButton = () => {
        props.setOpenMenu(prevState => !prevState)
    };

    return(
        <button 
            className={`menu-button  ${props.isMenuOpen && 'menu-button-open'} ${!props.isMenuOpen && 'menu-button-close'} `}
            onClick={onClickButton} >
            { !props.isMenuOpen &&  <img className="menu-icon" src={burger} alt="menu" />}
            { props.isMenuOpen &&  <AiOutlineClose size="40px"/>}
            
        </button>
    );
}

export { MenuButton };