import React, {useEffect, useState} from "react";

import hireUsImg from '../../Assets/Mask_Group_9.png';

import './index.css';
import './mobile.css';

function HireUs({toContact, offsetN, windowDimensions, getElmHeight}){

    // let height = windowDimensions.height*6;
    // let auxHeight = windowDimensions.height*5;
    // height = windowDimensions.width === 1440 ? height + 980 : height; 
    // auxHeight = windowDimensions.width === 1440 ? auxHeight + 900 : auxHeight;

    const [height, setHeight] = useState(windowDimensions.height*3)
    const [auxHeight, setAuxHeight] = useState(windowDimensions.height*2)
    useEffect(()=>{
        setHeight(
            getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients")) +
            getElmHeight(document.getElementById("ourwork")) +
            getElmHeight(document.getElementById("services")) +
            getElmHeight(document.getElementById("looking-for-container")) 
            );
            setAuxHeight(getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients"))+
            getElmHeight(document.getElementById("ourwork")) +
            getElmHeight(document.getElementById("services")) 
            );
   })

    return(
        <div className="hire-us-container" id="hire-us-container">
            <div className="hire-us-img-container">
                <p 
                    className="title hire-us-title"
                    style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? offsetN - height : 0}px)`}}>
                        Hire us to<span className="hire-us-title-elipsis">…</span>
                </p>
                <p className="title hire-us-title-mobile">...</p>
                <img  
                    style={{ transform: `translateY(${ (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? -offsetN + height : 0}px)`}}
                    className="hire-us-img" 
                    src={hireUsImg} 
                    alt="hire-us-img"/>
            </div>
            <div 
                className="hire-us-text-container"
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                    <p className="hire-us-text">Think ahead - Design a high-end brand - develop a visual strategy - forecast trends - give your brand personality - make you smile.</p>
                    <p className="title hire-us-lt-title" onClick={toContact} >Let's Talk!</p>
            </div>
        </div>
    );
}

export { HireUs };