import React from "react";
import logo from "../../Assets/Group_33.png";

import './index.css';
import './mobile.css';

function Footer(){
    return(
        <div className="footer">
            <img className="footer-logo" src={logo} alt="Logo" />
            <p className="footer-point-text ">ON POINT</p>
            <p className="footer-studio-text ">DIGITAL STUDIO</p>
        </div>
    );
}

export { Footer };