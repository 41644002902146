import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import "./ourWork.css";
import "./ourWork_mobile.css";

import {BsArrowRight} from "react-icons/bs";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function OurWork({ works, offsetN, windowDimensions, getElmHeight }){

    const navigate = useNavigate()
    const [mouseMoved, setMouseMoved] = useState(false);
    // console.log(r)
    const handleClick = (url) => {
        if (!mouseMoved) {
        navigate(url);
        }
    };

    const [sliderRef, setSliderRef] = React.useState(null)

    const responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2.5,
            }
        },
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 2.1
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1.7
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1.2
            }
        }
    ];

    const sliderSettings = {
        arrows: false,
        slidesToShow: 3.3,
        slidesToScroll: 1,
        infinite: false,
        responsive: responsive
      }

        // let height = windowDimensions.height*3;
        // height = windowDimensions.width === 1440 ? height + 400 : height;

    const [height, setHeight] = useState(windowDimensions.height*3)
    useEffect(()=>{
        setHeight(
            getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients")) +100
            );
   })

    return(
        <div className='our-work-container' id="ourwork">
            <p 
                className="title our-work-title our-work-title-dk"
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? offsetN - height : 0}px)`}}>
                    Some of our work…
            </p>
            <p 
                className="title our-work-title our-work-title-mb"
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? offsetN - height : 0}px)`}}>
                    Our work
            </p>
            <div 
                className="carrousel-container"
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                <Slider ref={setSliderRef} {...sliderSettings}>
                    {works.map(work => (
                        
                            <div className="carrousel-element" key={work.id}>
                                    <div 
                                    onMouseMove={() => setMouseMoved(true)}
                                    onMouseDown={() => setMouseMoved(false)}
                                    onMouseUp={() => handleClick(`/work/${work.id}`)}
                                    style={{ textDecoration: "none", cursor: "pointer" }}
                                    >
                                    <img className="work-image" src={work.img} alt={work.title} />
                                    <p className="work-text" dangerouslySetInnerHTML={{__html: work.title }}></p>
                                </div>
                            </div>
                        
                    ))}
                </Slider>
            </div>
            <div 
                className="our-work-text-container"
                style={{ transform: `translateY(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                <p className="our-work-digital-text">
                    Digital art at it’s finest  
                </p>
                <p className="our-work-explore-text" onClick={sliderRef?.slickNext}>
                    EXPLORA MÁS <BsArrowRight  size="50px"/>
                </p>
            </div>
        </div>
    );
}

export { OurWork }