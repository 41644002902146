import React, {useEffect} from "react";
import './index.css';
import './mobile.css';

import { useClients } from "./useClients";
import { useWork } from "./useWork";

import { MenuButton } from "../MenuButton";
import { Hero } from "../Hero";
import { WeAre } from "../WeAre";
import { Clients } from "../Clients";
import { OurWork } from "../OurWork";
import { Services } from "../Services";
import { LookingFoor } from "../LookingFoor";
import { HireUs } from "../HireUs";
import { Contact } from "../Contact";
import { Footer } from "../Footer";
import { Menu } from "../Menu";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function getElmHeight(node) {
    const list = [
        'margin-top',
        'margin-bottom',
        'border-top',
        'border-bottom',
        'padding-top',
        'padding-bottom',
        'height'
    ]

    const style = window.getComputedStyle(node)
    return list
        .map(k => parseInt(style.getPropertyValue(k), 10))
        .reduce((prev, cur) => prev + cur)
}

function LandingPage(){

    const [offset, setOffset] = React.useState(0);
    const [offsetN, setOffsetN] = React.useState(0);
    const [offsetF, setOffsetF] = React.useState(0);
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    const [openMenu, setOpenMenu] = React.useState(false);
    const clients =  useClients();
    const { getWorks, findWork } = useWork();

    const toContact = () =>{
        window.location.replace("/home#contact");
    }

    function handleScroll() {
        setOffset(window.pageYOffset - 100);
        setOffsetN(window.pageYOffset);
        setOffsetF(window.pageYOffset + 100);

    }

    useEffect(() => {

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    });
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions]);
    
    return(
        <div className="landing-container"> 

            <div className="landing-content">
                <MenuButton  
                    isMenuOpen={openMenu}
                    setOpenMenu={setOpenMenu}
                />
                <Hero />
                <WeAre toContact={toContact} offsetN={offsetN} windowDimensions={windowDimensions} getElmHeight={getElmHeight} />
                <Clients clients={clients} offsetN={offsetN} windowDimensions={windowDimensions} getElmHeight={getElmHeight}/>
                <OurWork works={getWorks()} offsetN={offsetN} windowDimensions={windowDimensions} getElmHeight={getElmHeight}/>
                <Services offsetN={offsetN} windowDimensions={windowDimensions} getElmHeight={getElmHeight}/>
                <LookingFoor toContact={toContact} offsetN={offsetN} windowDimensions={windowDimensions} getElmHeight={getElmHeight}/>
                <HireUs toContact={toContact} offsetN={offsetN} windowDimensions={windowDimensions} getElmHeight={getElmHeight}/>
                <Contact offsetN={offsetN} windowDimensions={windowDimensions} getElmHeight={getElmHeight}/>
            </div>
            <Footer />

            {!!openMenu &&
                <Menu setOpenMenu={setOpenMenu}/>
            }

        </div>
    );
}

export { LandingPage };