import React, {useEffect, useState} from "react";
import lfImg from "../../Assets/Mask_Group_8.png"

import "./index.css";
import "./mobile.css";

function LookingFoor({toContact, offsetN, windowDimensions, getElmHeight}){
    // let height = windowDimensions.height*5;
    // let auxHeight = windowDimensions.height*4;
    // height = windowDimensions.width === 1440 ? height + 900 : height; 
    // auxHeight = windowDimensions.width === 1440 ? auxHeight + 900 : auxHeight;


    const [height, setHeight] = useState(windowDimensions.height*3)
    const [auxHeight, setAuxHeight] = useState(windowDimensions.height*2)
    useEffect(()=>{
        setHeight(
            getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients")) +
            getElmHeight(document.getElementById("ourwork")) +
            getElmHeight(document.getElementById("services")) 
            );
            setAuxHeight(getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients"))+
            getElmHeight(document.getElementById("ourwork")) 
            );
   })

    return(
        <div className="looking-for-container" id="looking-for-container">
            <div className="looking-for-text-container">
                <p 
                    className="title looking-for-title text-align-left"
                    style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? offsetN - height : 0}px)`}}>
                    We are looking for<span className="lf-dk-points">…</span>
                </p>
                <p className="lf-mb-points title looking-for-title">...</p>
                <p className="looking-for-text margin-bottom-100"
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                    Changemakers, strong individuals, people with personality, brands on a mission. Those who dare to stand out in the world.
                </p>
                <p className="looking-for-text margin-bottom-100 looking-for-text-dk "
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}}> 
                    <b>Bold moves </b>- that’s what we like.
                </p>
                <p className="looking-for-text margin-bottom-100 looking-for-text-mb "> <b>Bold moves </b>- <span className="looking-for-text-mb-s">that’s what we like.</span></p>
                <p className="title text-align-left looking-for-lets-talk-text"
                style={{ transform: `translateY(${ (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? -offsetN + height : 0}px)`}}
                onClick={toContact}
                >Let's Talk!</p>
            </div>
            <div className="looking-for-img-container"
                style={{ transform: `translateY(${ (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? -offsetN + height : 0}px)`}}
            >
                <img  className="looking-for-img" src={lfImg} alt="looking for" />
            </div>
        </div>
    );
}

export { LookingFoor };