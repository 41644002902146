import React from "react";
import ReactDOM from 'react-dom';

import './index.css';
import './mobile.css';

function Menu(props){

    const redirect = (id)=>{
        props.setOpenMenu(prevState => !prevState);
        window.location.replace("/home#"+id);
    }

    return ReactDOM.createPortal(
        <div className="menu-container">
            <div className="menu-top-container">
                <p className="menu-top-text">SINCE 2020</p>
            </div>
            <div className="menu-main-container">
                 <div className="menu-left-container">
                    <section className="text-rotate menu-section-left">
                        <p className="menu-left-text ">Digital art at it’s finest</p>
                    </section>
                </div>
                <div className="menu-right-container">
                    <p className="menu-stair-text" ><span onClick={()=>{ redirect('clients') }}>Clients</span>
                        <span className="menu-stair-second-lvl" ><span onClick={()=>{ redirect('services') }}>Services</span>
                            <span className="menu-stair-third-lvl" ><span onClick={()=>{ redirect('ourwork') }}>Our Work</span>
                                <span className="menu-stair-fourth-lvl" onClick={()=>{ redirect('contact') }}>Contact us</span>
                            </span>
                        </span>
                    </p>
                </div>
                <div className="menu-rigth-container-mobile">
                <p className="menu-stair-text" onClick={()=>{ redirect('clients') }}>Clients</p>
                <p className="menu-stair-text" onClick={()=>{ redirect('services') }}>Services</p>
                <p className="menu-stair-text" onClick={()=>{ redirect('ourwork') }}>Our Work</p>
                <p className="menu-stair-text" onClick={()=>{ redirect('contact') }}>Contact us</p>
            </div>
            </div>
        </div>,
        document.getElementById('menu')
    );
}

export { Menu };