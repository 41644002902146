import React from "react";
import { useParams } from "react-router-dom";

import "./work.css";
import "./work_mobile.css";
import {FaInstagram, FaYoutube} from "react-icons/fa";
import {BsArrowRight, BsArrowLeft} from "react-icons/bs";

import { MenuButton } from "../MenuButton";
import { Menu } from "../Menu";
import { useWork } from "../LandingPage/useWork";

function Work(){
    const [openMenu, setOpenMenu] = React.useState(false);

    let params = useParams();
    const { getWorks, findWork, getLatest } = useWork();
    const work = findWork(params.id);

    const back = () => {
        window.location.replace("/home#ourwork");
    }

    const next = () => {
        let id = work.id + 1;
        id <= getLatest().id ? redirect(id) : redirect(1)
    }

    const redirect = (id) => {
        window.location.replace("/work/"+id);
    }

    console.log(work);
    return(
        <div className="work-container">

                <MenuButton  
                    isMenuOpen={openMenu}
                    setOpenMenu={setOpenMenu}
                />

            <div className="work-top-text-container">
                <p className="hero-top-text work-top-text">SINCE 2020</p>
            </div>

            <div className="work-main-container">
                <div className="work-img-container">
                    <img className="work-img" src={work.img} alt={work.title} />
                </div>
                <div className="work-text-container">
                    <p className="title work-title">Projects</p>
                    <p className="wotk-subtitle" dangerouslySetInnerHTML={{__html: work.title }}></p>
                    <p className="work-description">{work.description}</p>
                    { work.link != "" &&
                        <p className="work-find-text">Lo puedes encontrar en:</p>
                    }
                        {
                            (work.icon == 1 && work.link != "") &&
                            <a href={work.link} target="_blank">
                                <p className="work-link" >
                                    <FaYoutube className="work-link-icon only-dk" size="40px"/>
                                    <FaYoutube className="work-link-icon only-mc" size="20px"/> 
                                    <span className="work-link-text">Ver video</span>
                                </p>
                            </a>
                        }
                        {
                            (work.icon == 2 && work.link != "") && 
                            <a href={work.link} target="_blank">
                                <p className="work-link" >
                                    <FaInstagram className="work-link-icon only-dk" size="40px"/>
                                    <FaInstagram className="work-link-icon only-mc" size="20px"/> 
                                    <span className="work-link-text">Ver Fotografías</span>
                                </p>
                            </a>
                        }
                </div>
            </div>
            <div className="work-actions-container">
                <p className="work-back-action" onClick={back}>
                    <BsArrowLeft className="only-dk" size="70px"/>
                    <BsArrowLeft className="only-mc"  size="35px"/> 
                    BACK
                </p>
                <p className="work-next-action" onClick={next} >
                    EXPLORE MORE
                    <BsArrowRight className="only-dk"  size="70px"/>
                    <BsArrowRight className="only-mc"  size="35px"/>
                </p>
            </div>

            <section className="text-rotate  hero-left-section work-left-section">
                <p className="hero-left-text work-left-text">Digital art at it’s finest</p> 
            </section>

            {!!openMenu &&
                <Menu setOpenMenu={setOpenMenu}/>
            }

        </div>
    );
}

export {Work}