import React, {useEffect, useState} from "react";
import axios from "axios";

import { useForm } from 'react-hook-form';

import './index.css';
import './mobile.css';

import instagramImg from "../../Assets/Group_45.png";
import spotifyImg from "../../Assets/Group_44.png";

function Contact({offsetN, windowDimensions, getElmHeight}){

    // let height = windowDimensions.height*7;
    // let auxHeight = windowDimensions.height*6;
    // height = windowDimensions.width === 1440 ? height + 1210 : height; 
    // auxHeight = windowDimensions.width === 1440 ? auxHeight + 1210 : auxHeight;

    const [height, setHeight] = useState(windowDimensions.height*3)
    const [auxHeight, setAuxHeight] = useState(windowDimensions.height*2)

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(()=>{
        setHeight(
            getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients")) +
            getElmHeight(document.getElementById("ourwork")) +
            getElmHeight(document.getElementById("services")) +
            getElmHeight(document.getElementById("looking-for-container")) +
            getElmHeight(document.getElementById("hire-us-container")) +100
            );
            setAuxHeight(getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients"))+
            getElmHeight(document.getElementById("ourwork")) +
            getElmHeight(document.getElementById("services")) +
            getElmHeight(document.getElementById("looking-for-container")) 
            );
   })

    const nameRegex = RegExp(/^[A-Za-z]{3,}/)
    const phoneRegex = RegExp(/^[0-9]{8}/)
    const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

    const onSubmit = (data) => {
        console.log(data)
            const formData = new FormData();
            formData.append("email_to_sent", "hello@onpointdigitalstudio.com");
            formData.append("web_page", 'OnPoint');
            formData.append("content", `Name: ${data.name}\nPhone: ${data.phone}\nEmail: ${data.email}\nMessage: ${data.message}`);
            axios.post(`https://api-ecommerce.devpackgroup.com/api/v1/send_email_web_pages/`, formData).then(res => {
                alert("Mensaje enviado!")
                console.log(res)
            });
          
    }

    return(
        <div className="contact-container" id="contact">
            <div className="contact-title-container">
                <p className="title contact-title"
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? offsetN - height : 0}px)`}}>
                    Don’t be shy, <span className="looking-for-text-mb-s">Say Hi!</span>
                </p>
            </div>
            <div className="contact-content-container">
                <div className="contact-form-container"
                style={{ 
                    // transform: `translateY(${ (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? -offsetN + height : 0}px)`,
                    // display: (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? "absolute" : "relative"
                }}
                >
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <label className="form-label">
                            Name
                            {errors.name?.type =="required" && (
                                <span className="form-label" style={{ color:"red" }}>
                                    &nbsp;required field
                                </span>
                            )}
                            {errors.name?.type == "pattern" && (
                                <span className="form-label" style={{ color:"red" }}>
                                    &nbsp;invalid value
                                </span>
                            )}    
                        </label> <br />
                        <input className="form-input" name="name" type="text" style={ errors.name != undefined  ? { backgroundColor: "#FFAAAA" } : {} } {...register("name", {required: true, maxLength: 80, pattern:nameRegex })}/> <br />
                        <label className="form-label">
                            Email
                            {errors.email?.type =="required" && (
                                <span className="form-label" style={{ color:"red" }}>
                                     &nbsp;required field
                                </span>
                            )}
                            {errors.email?.type == "pattern" && (
                                <span className="form-label" style={{ color:"red" }}>
                                    &nbsp;invalid value
                                </span>
                            )}
                        </label> <br />
                        <input className="form-input" name="email" type="text" style={ errors.email != undefined  ? { backgroundColor: "#FFAAAA" } : {} }{...register("email", {required: true, pattern:emailRegex })}/> <br />
                        <label className="form-label">
                            Phone
                            {errors.phone?.type =="required" && (
                                <span className="form-label" style={{ color:"red" }}>
                                     &nbsp;required field
                                </span>
                            )}
                            {errors.phone?.type == "pattern" && (
                                <span className="form-label" style={{ color:"red" }}>
                                    &nbsp;invalid value
                                </span>
                            )}
                        </label> <br />
                        <input className="form-input" name="tel" type="text" style={ errors.phone != undefined  ? { backgroundColor: "#FFAAAA" } : {} } {...register("phone", {required: true, pattern:phoneRegex })}/> <br />
                        <label className="form-label">
                            Message
                            {errors.message?.type =="required" && (
                                <span className="form-label" style={{ color:"red" }}>
                                     &nbsp;required field
                                </span>
                            )}
                        </label> <br />    
                        <textarea className="form-area" name="message" style={ errors.message != undefined  ? { backgroundColor: "#FFAAAA" } : {} } {...register("message", {required: true, maxLength: 800})}/>
                        <button className="green-button button-lg" type="submit">Send</button>
                    </form>
                </div>
                <div className="contact-info-container">
                    <div className="contact-email-container-mobile">
                        <p className="contact-email-text-mobile">Hello@onpointdigitalstudio.com</p>
                    </div>
                    <div className="contact-img-container"
                    style={{ 
                        // transform: `translateY(${ (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? -offsetN + height : 0}px)`,
                        // display: (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? "absolute" : "relative"
                    }}
                    >
                        <div className="inst-container">
                            <a href="https://www.instagram.com/onpointstudio_/" target="_blank"><img className="inst-img" src={instagramImg} alt="instragram"/></a>
                            <p className="contact-text">SHOW US YOUR LOVE</p>
                        </div>
                        <div className="spot-container">
                        <a href="https://open.spotify.com/playlist/4mjSO4Ul8eYG2fdcRZl3ta?si=fb04fd0d87c541b2" target="_blank"><img className="spot-img" src={spotifyImg} alt="spotify"/></a>
                            <p className="contact-text">VIBE W/ US</p>
                        </div>
                    </div>
                    <div className="contact-email-container">
                        <p className="contact-email-text"
                        style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                            Hello@onpointdigitalstudio.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Contact };