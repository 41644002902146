import React, {useEffect, useState} from 'react';

import "./index.css";
import "./mobile.css";

function Clients({ clients, offsetN, windowDimensions, getElmHeight }){

    // let height = windowDimensions.height*2;
    // let auxHeight = windowDimensions.height;
    // height = windowDimensions.width === 1440 ? height + 300 : height;
    // auxHeight = windowDimensions.width === 1440 ? auxHeight + 300 : auxHeight;


    const [height, setHeight] = useState(windowDimensions.height*2)
    const [auxHeight, setAuxHeight] = useState(windowDimensions.height*2)
    useEffect(()=>{
        setHeight(
            getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) - 100
            );
        setAuxHeight(getElmHeight(document.getElementById("hero-container")));
   })

    const getStyle = (id) => {
        let style;
        switch (id) {
            case 1:
            case 2:
            case 4:
                style = { transform: `translateX(${ offsetN <= height ? offsetN - height : 0}px)` }
                break;
            case 3:
            case 5:
            case 6:
                style = { transform: `translateX(${ offsetN <= height ? -offsetN + height : 0}px)` }
                break;
            case 7:
            case 8:
                style = { transform: `translateY(${ (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? -offsetN + height : 0}px)`}
                break;
            default:
                style = { }
        }
        return style;
    }

    return(
        <div className="clients-container" id='clients'>
            <div className='clients-title-container'>
                <p className='title'>CLIENTS</p>
            </div>
            <div className='clients-main-container'>
                <div className='clients-first-row only-dk-cl'>
                    {
                        clients.desktop.first.map(client => (
                            <img className='client-img' style={ getStyle(client.id) } src={client.img} alt={client.name} key={client.name} />
                        ))
                    }
                </div>
                <div className='clients-second-row only-dk-cl'>
                    {
                        clients.desktop.second.map(client => (
                            <img className='client-img' style={ getStyle(client.id) } src={client.img} alt={client.name} key={client.name} />
                        ))
                    }
                </div>
                <div className='clients-third-row only-dk-cl'>
                    {
                        clients.desktop.third.map(client => (
                            <img className='client-img' style={ getStyle(client.id) } src={client.img} alt={client.name} key={client.name} />
                        ))
                    }
                </div>

                {
                    clients.mobile.map(client => (
                        <div className='client-image-div' key={client.name} >
                            <img className='client-img only-mb' src={client.img} alt={client.name} />
                        </div>
                    ))
                }

            </div>
        </div>
    );
}

export { Clients };