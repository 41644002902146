import React from "react";

import logo from "../../Assets/OP_logo.png"

import './index.css';
import './mobile.css';

function Hero(){
    return(
        <div className="hero-container" id="hero-container">
            <div className="hero-top-text-container">
                <p className="hero-top-text">SINCE 2020</p>
            </div>
            <div className="hero-main-container">
                <div className="hero-logo-container">
                    <img className="hero-logo" src={logo} alt="Logo" />
                </div>
                <div className="hero-title-container">
                    <p className="hero-point-text">ON POINT</p>
                    <p className="hero-studio-text">DIGITAL STUDIO</p>
                    <p className="hero-subtitle">Explore <span className="looking-for-text-mb-s">greatness</span></p>
                </div>
            </div>
            <section className="text-rotate  hero-left-section">
                <p className="hero-left-text">Digital art at it’s finest</p>
            </section>
        </div>
    );
}

export { Hero };