import React from "react";

import {FaInstagram, FaYoutube} from "react-icons/fa";

import tusaImg from "../../Assets/works/tusa.png"
import moradoImg from "../../Assets/works/morado.png"
import chImg from "../../Assets/works/ch.png"
import cacaoImg from "../../Assets/works/cacao.png"
import shotsImg from "../../Assets/works/shots.png"
import foodStylingImg from "../../Assets/works/foodstyling.png"

function useWork(){
    let ourWorks = [];

    //icon -> 1 - youtube, 2 - instagram
    let abstractVideo = { id: 1, title:"MORADO ABSTRACT VIDEO - J BALVIN", description:"Proyectamos a traves de amazing 3D art las lyrics de Morado by JBalvin,", img:moradoImg, link:"", icon:1 };
    let tusa = { id: 2, title:"TUSA", description:"Sin duda alguna un milestone event, trabajamos la línea gráfica, conceptualización y animación 2D para Universal Music Group y suproyecto Tusa de Karol G x Nicki Minaj (un anthem del 2019)", img:tusaImg, link:"https://www.youtube.com/watch?v=HND7qHLpyUI", icon:1 };
    let ch = { id: 3, title:"CAROLINA HERRERA SALES FORCE VIDEO", description:"Creamos un video utilizando todos los assets de Carolina Herrera para instruir y motivar a su fuerza de venta al rededor del mundo. Unespectaculo de visuales....", img:chImg, link:"", icon:1 };
    let cacao = { id: 4, title:"CACAO 70", description:"Cacao 70, from Canada to the world... El 2D art de Cacao respira Wes Anderson, y en On Point Studio no podemos ser mas fans de Wes. Gozamos cada proyecto de nuestro Cliente Cacao 70, Bon Appétit et Bon Voyage!", img:cacaoImg, link:"https://www.instagram.com/cacao70guatemala/" , icon:2};
    let shots = {id: 5, title:"PRODUCT SHOTS", description:"Uno de nuestros fuertes, dar a conocer de manera única y creativa tus productos...", img:shotsImg, link:"https://www.instagram.com/onpointstudio_/", icon:2 };
    let foodStyling = { id: 6, title:"FOOD STYLING", description:"Otro fuerte, antojar a la gente por medio de stills fotográficos...", img:foodStylingImg, link:"https://www.instagram.com/onpointstudio_/", icon:2 };

    ourWorks.push(abstractVideo);
    ourWorks.push(tusa);
    ourWorks.push(ch);
    ourWorks.push(cacao);
    ourWorks.push(shots);
    ourWorks.push(foodStyling);

    const getWorks = () =>{
        return ourWorks;
    }

    const findWork = (id) => {
        return ourWorks.find(work => {
            return work.id == id;
        });
    }

    const getLatest = () => {
        return ourWorks.slice(-1)[0];
    }

    return({ getWorks, findWork, getLatest });
 }

export { useWork };