import React from "react";

import logoBohru from "../../Assets/clients/logo_bohru.png";
import logoCacao from "../../Assets/clients/logo_cacao.png";
import logoCH from "../../Assets/clients/logo_CH.png";
import logoHacienda from "../../Assets/clients/logo_de_la_hacienda.png";
import logoGourmeta from "../../Assets/clients/logo_gourmeta.png";
import logoPicazzo from "../../Assets/clients/logo_picazzo.png";
import logoSobremesa from "../../Assets/clients/logo_sobremesa.png";
import logoUniversal from "../../Assets/clients/logo_universal.png";

function useClients(){
    // let clients = [];

    const clients = {
        desktop: {
            first: [],
            second: [],
            third: []
        },
        mobile: []
    };

    let ch = { name: 'CH', img:logoCH, id:1 };
    let cacao = { name: 'cacao', img:logoCacao, id:6 };
    let picazzo = { name: 'picazzo', img:logoPicazzo, id:3 };
    let universal = { name: 'universal', img:logoUniversal, id:4 };
    let gourmeta = { name: 'gourmeta', img:logoGourmeta, id:5 };
    let bohru = { name: 'bohru', img:logoBohru, id:2 };
    let sobremesa = { name: 'sobremesa', img:logoSobremesa, id:7 };
    let hacienda = { name: 'hacienda', img:logoHacienda, id:8 };

    clients.mobile.push(ch);
    clients.mobile.push(gourmeta);
    clients.mobile.push(universal);
    clients.mobile.push(bohru);
    clients.mobile.push(cacao);
    clients.mobile.push(hacienda);
    clients.mobile.push(sobremesa);
    clients.mobile.push(picazzo);

    clients.desktop.first.push(ch)
    clients.desktop.first.push(bohru)
    clients.desktop.first.push(picazzo)
    clients.desktop.second.push(universal)
    clients.desktop.second.push(gourmeta)
    clients.desktop.second.push(cacao)
    clients.desktop.third.push(sobremesa)
    clients.desktop.third.push(hacienda)

    return clients;
}

export { useClients };