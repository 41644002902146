import { screen } from "@testing-library/react";
import React, {useEffect, useState} from "react";
import './index.css';
import './mobile.css';

function WeAre({toContact, offsetN, windowDimensions, getElmHeight}){

    // let height = windowDimensions.height;
    //     height = windowDimensions.width === 1440 ? height + 200 : height;


    const [height, setHeight] = useState(windowDimensions.height)
    useEffect(()=>{
         setHeight(getElmHeight(document.getElementById("hero-container")));
    })

    return(
        <div className="we-are-container padding-top-100 padding-bottom-100" id="we-are-container" >
            <p 
                className="margin-bottom-100 we-are-text we-are-title" 
                style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? offsetN - height : 0}px)`}}>
                    WE ARE THE
            </p>
            <div 
                className="subtitle-container"
                style={{ transform: `translateX(${ windowDimensions.width >  440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                    <p className="subtitle">Design, Branding, Creative,</p>
                    <p className="subtitle margin-bottom-100">Identity, Strategy</p>
            </div>
            <div className="subtitle-container-mobile">
                
                    <p className="subtitle">Design</p>
                    <p className="subtitle">Branding</p>
                    <p className="subtitle">Creative</p>
                    <p className="subtitle">Identity</p>
                    <p className="subtitle margin-bottom-100">Strategy</p>
                
            </div>
            <p 
                className="we-are-title"
                style={{ transform: `translateY(${ windowDimensions.width >  440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                    STUDIO YOU ARE LOOKING FOR
            </p>
            <p 
                className="we-are-title margin-bottom-100"
                style={{ transform: `translateY(${ windowDimensions.width >  440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                    ...
            </p>
            <button 
                className="green-button button-md margin-bottom-100 no-margin-mobile" 
                style={{ transform: `translateX(${ windowDimensions.width >  440 && offsetN <= height ? -offsetN + height : 0}px)`}}
                onClick={toContact}>
                    LET'S TALK!
            </button>
            <br />
            <p 
                className="normal-text"
                style={{ transform: `translateY(${ windowDimensions.width >  440 && offsetN <= windowDimensions.height ? -offsetN + windowDimensions.height : 0}px)`}}>
                    EXPLORE GREATNESS
            </p>
        </div>
    );
}

export { WeAre };