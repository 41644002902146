import React, {useEffect, useState} from "react";

import './index.css';
import './mobile.css';

function Services({windowDimensions, offsetN, getElmHeight}){

    // let height = windowDimensions.height*4;
    // let auxHeight = windowDimensions.height*3;
    // height = windowDimensions.width === 1440 ? height + 550 : height;
    // auxHeight = windowDimensions.width === 1440 ? auxHeight + 550 : auxHeight;
    // console.info(height, offsetN)

    const [height, setHeight] = useState(windowDimensions.height*3)
    const [auxHeight, setAuxHeight] = useState(windowDimensions.height*2)
    useEffect(()=>{
        setHeight(
            getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients")) +
            getElmHeight(document.getElementById("ourwork")) 
            );
            setAuxHeight(getElmHeight(document.getElementById("hero-container")) +
            getElmHeight(document.getElementById("we-are-container")) +
            getElmHeight(document.getElementById("clients")));
   })

    return(
        <div className="services-container" id="services">
            <div className="text-container">
                <p 
                    className="title services-title"
                    style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}}>
                        <span>SER</span><span className="service-span" >VICES</span>
                </p>
                <p 
                    className="services-subtitle"
                    style={{ transform: `translateX(${ windowDimensions.width > 440 && offsetN <= height ? offsetN - height : 0}px)`}}>
                        LONG STORY SHORT <span className="looking-for-text-mb-s">WE ARE GOOD AT:</span>
                </p>
            </div>
            <div className="stair-container"
                style={{ transform: `translateY(${ (windowDimensions.width > 440 && offsetN >= auxHeight) && offsetN <= height ? -offsetN + height : 0}px)`}}
                >
                    {/* style={{ transform: `translateY(${ windowDimensions.width > 440 && offsetN <= height ? -offsetN + height : 0}px)`}} */}
                <p className="stair service-stair-text"><span className="first-stair-service" >Brand Strategy</span>
                    <span className="stair-second-lvl"><span className="second-stair-service" >Identity & branding</span>
                        <span className="stair-third-lvl"><span className="third-stair-service" >Creative Direction</span>
                            <span className="stair-fourth-lvl"><span className="fourth-stair-service" >Video art</span>
                                <span className="stair-fifth-lvl"><span className="fifth-stair-service" >Digital content</span></span>
                            </span>
                        </span>
                    </span>
                </p>
            </div>
            <div className="stair-container-mobile">
                <p className="service-stair-text">Brand Strategy</p>
                <p className="service-stair-text">Identity & branding</p>
                <p className="service-stair-text">Creative Direction</p>
                <p className="service-stair-text">Video art</p>
                <p className="service-stair-text">Digital content</p>
            </div>
        </div>
    );
}

export { Services };