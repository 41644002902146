//import logo from './logo.svg';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import './mobile.css';

import { ComingSoon } from '../ComingSoon';
import { LandingPage } from '../Page/LandingPage';
import { Work } from '../Page/Work';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<LandingPage/>} />
        <Route path='/work' element={<Work/>} >
          <Route path=':id' element={<Work/>} />
        </Route>
      </Routes>
    </BrowserRouter>
    
  );
}

export { App };
